@tailwind base;
@tailwind components;
@tailwind utilities;

@import './customTailwind/main.scss';

@font-face {
    font-family: 'Tajawal';
    src: url('./../public/assets/fonts/Tajawal/Tajawal-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Tajawal';
    src: url('./../public/assets/fonts/Tajawal/Tajawal-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    @apply bg-gray-50 dark:bg-gray-800;
    font-family: 'Tajawal', sans-serif;
    user-select: none;
}


@media print {
    /* Hide everything by default */
    body * {
        @apply invisible;
    }

    /* Show only print content */
    .print\:block,
    .print\:block * {
        @apply visible;
    }

    /* Reset colors for printing */
    body {
        @apply bg-white text-black;
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
        margin: 0; /* Ensure no margin on body */
    }

    /* Page setup */
    @page {
        size: A4;
        margin: 0; /* No margin on the printed page */
    }

    /* Ensure Tajawal font is used in print */
    * {
        font-family: 'Tajawal', sans-serif !important;
    }

    /* Table specific print styles */
    table {
        page-break-inside: auto;
    }
    
    tr {
        page-break-inside: avoid;
        page-break-after: auto;
    }

    /* Ensure borders print correctly */
    .border-black {
        border-color: #000 !important;
    }
}

// /* Print styles */
// @media print {
//     /* Hide everything by default */
//     body * {
//         @apply invisible;
//     }

//     /* Show only print content */
//     .print\:block,
//     .print\:block * {
//         @apply visible;
//     }

//     /* Reset colors for printing */
//     body {
//         @apply bg-white text-black;
//         print-color-adjust: exact;
//         -webkit-print-color-adjust: exact;
//     }

//     /* Hide non-printable elements */
//     .print\:hidden {
//         @apply hidden;
//     }

//     /* Page setup */
//     @page {
//         size: A4;
//         margin: 10mm;
//     }

//     /* Ensure Tajawal font is used in print */
//     * {
//         font-family: 'Tajawal', sans-serif !important;
//     }

//     /* Table specific print styles */
//     table {
//         page-break-inside: auto;
//     }
    
//     tr {
//         page-break-inside: avoid;
//         page-break-after: auto;
//     }

//     /* Ensure borders print correctly */
//     .border-black {
//         border-color: #000 !important;
//     }
// }








// @tailwind base;
// @tailwind components;
// @tailwind utilities;

// @import './customTailwind/main.scss';

// @font-face {
//     font-family: 'Tajawal';
//     src: url('./../public/assets/fonts/Tajawal/Tajawal-Regular.ttf') format('truetype');
//     font-weight: normal;
//     font-style: normal;
//   }
  
//   @font-face {
//     font-family: 'Tajawal';
//     src: url('./../public/assets/fonts/Tajawal/Tajawal-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
//   }

// body {
//     @apply bg-gray-50 dark:bg-gray-800;
//     font-family: 'Tajawal', sans-serif;
//     user-select: none;
// }
